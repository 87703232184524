/*******************************
         Site Overrides
*******************************/
* {
  font-family: "OfficinaSansITCPro-Book";
}
::placeholder {
  color: black !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: black;
}

a {
    color: #0082BA;
    text-decoration: none;
}

/*******************************
              PLUS
*******************************/
.ui.menu .active.item {
    border-color: #0082BA !important;
    color: #0082BA !important;
}

.ui.menu .plus.active.item {
    border-color: #0082BA !important;
    color: #0082BA !important;
}

.ui.primary.buttons .button, .ui.primary.button.plus {
    background-color: #0082BA !important;
    color: white !important
} 

.dznSCu.plus {
    border-color: #0082BA !important;
}
.fvnCEj.plus {
    border: 1px solid #0082BA !important;
    color: #0082BA !important;  
}

.ui.label.listenPickImagesResultContainer {
  padding: 16px!important;
}
.ui.label > img.listenPickResult {
    height: inherit!important;
    width: auto!important;
    margin: 0 auto;
    display: block;
}

.ui.label.listenPickImagesResultContainer.pickImagesButton, .pickImagesButton {
    padding: 0!important;
    width: calc(50% - 6px)!important;
    transition: all .3s ease;
    position: relative;
}

.ui.secondary.button.pickImagesButton, .ui.button.pickImagesButton:hover, .ui.button.pickImagesButton.secondary:hover {
    border: 5px solid #0082BA !important;
}

.pickImagesButton img, .ui.label.pickImagesButton img.listenPickResult  {
    width: 100%!important;
}

.pickImagesButton.overlay:after {
    content: '';
    position: absolute;
    background: #0082BA;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
}

button.ui.rightBordered:after {
    content: '';
    width: 3px;
    height: 100%;
    background: #0082BA !important;
    position: absolute;
    right: 8px;
    top: 0;
}

h4.plus {
    color: #0082BA !important;
}

input[type="number"].numeric {
    max-width: 200px!important;
    -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button.ui.font22 p {
    font-size: 22px!important;
}

.ui.secondary.buttons .button.wordBlue, .ui.secondary.button.wordBlue {
    background-color: #ffffff!important;
    color: #2582ba!important;
    text-shadow: none!important;
}

.ui.secondary.buttons .button.wordBlue p, .ui.secondary.button.wordBlue p {
    font-family: OfficinaSansITCPro-Bold !important;
}

.markGapsP {
    position: relative;
    right: 5px;
    z-index: -1;
}