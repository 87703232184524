/*******************************
         Site Overrides
*******************************/
.ui.tiny.progress .bar {
  height: 2px !important;

  @media (min-width: 992px) {
    height: 4px !important;
  }
}

.ui.progress:first-child {
    margin: 0px 0px 5px !important;
}

