/*******************************
         Site Overrides
*******************************/

.ui.input > input {
  background-color: #F4F4F4;
  height: 48px;
  letter-spacing: 1.5px !important;
  line-height: 21px;
  padding: 12px 16px;
  margin: 0px;
  font-weight: 300px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.login-input {
  background-color: red;
}

.ui.input.full-width {
  width: 100%;
}

.ui.input.small {
  width: 30%
}

.ui input {
    background: none!important;
    border: none!important;
    border-bottom: 1px solid black!important;
    border-radius: 0!important;
}