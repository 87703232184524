/*******************************
         Site Overrides
*******************************/
.ui.segment {
  margin: 8px 0px;
  padding: 8px;
  height: 100px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
  background-color: #FFFFFF;
}

.ui.disabled.segment {
    opacity: 0.3;
    background-color: #F4F4F4;
    color: #000000;
    
    p, .progress{
      display: none !important;
    }
}

.ui.segment.plus {
    background-color: #F2F9FB !important;
}

.ui.segment.optional {
    background-color: #E5E4E4 !important;
}