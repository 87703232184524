/*******************************
        User Overrides
*******************************/

.ui.styled.accordion {
  box-shadow: none;
}

.ui.styled.accordion .title, .ui.styled.accordion .accordion .title {
  padding: 16px 0px;
}