/*******************************
         Site Overrides
*******************************/

.ui.secondary.menu {
  margin: 0px;
  height: 48px;
}

.ui.secondary.pointing.menu {
  border-bottom:    0;
}

.ui.secondary.pointing.menu .item {
  border-bottom-width: 1px;
}

@media (max-width: 992px) {
  .ui.secondary.pointing.menu .item {
    border-bottom-style: none !important;
  }
}