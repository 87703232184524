/*******************************
         Site Overrides
*******************************/

.ui.button {
  border: 1px solid #D8D8D8;
  font-family: OfficinaSansITCPro-Bold;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1.5px;
  line-height: 21px;
  text-align: initial;
  color: #000;
  border-radius: 4px;
  margin-right: 0px;
}

.ui.primary.button {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-align: center;
}

/* 

.ui.button.plus {
  background-color: #FFEAE5;
  color: #EA6A50;
  border: none;
}

.ui.button.plus > p {
  font-weight: bold !important;
  font-size: 19px !important;
}

.ui.button.plus:hover {
  background-color: #e95b3f;
  color: white;
}

.ui.secondary.button.plus {
  background-color: #EA6A50;
  color: white;
}

.ui.secondary.button.plus:hover {
  background-color: #e95b3f;
}

.ui.secondary.buttons .button:hover, .ui.secondary.button:hover {
  border: none !important;
}

.ui.green.button {
  border: none;
}

.ui.orange.button {
  background-color: #EA6A50;
  color: white;
}

.ui.orange.button:active {
  background-color: #EA6A50;
  color: white;
}

.ui.orange.button:hover {
  background-color: #e95b3f;
}

.ui.orange.button:focus {
  background-color: #EA6A50;
  color: white;
} 

*/

.ui.button.pos-fixed {
  position: fixed;
}

.ui.button.bottom {
  bottom: 0px;
}

.ui.button.full-width {
  max-width: 1300px;
  width:     100%;
}

.ui.button.login-btn {
  margin-top: 27px;
  max-height: 44px !important;
}

.ui.button.message-btn {
  margin-top: 8px !important;
  max-height: 44px !important;
}

.ui.button.rounded {
  border-radius: 4px !important;
}

.ui.button.disabled.btn-next-disabled {
    color: #CFCFCE !important;
    background-color: #84b3a7 !important;
    opacity: 1 !important;
}

.ui.button.disabled.btn-next-disabled.plus {
    color: #CFCFCE !important;
    background-color: #87A5B2 !important;
    opacity: 1 !important;
}

.ui.button.horentest {
    max-height: 61px !important;
    text-align: center;
    margin-top: 8px;
    height: 61px;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 21px;
}

.ui.button.bordered {
  background-color: #FFFFFF;
  color: #0082BA;
  border: 1px solid #0082BA;
  
}

.ui.button.blue {
  background-color: #0082BA;
}

.ui.button.blue:hover {
  background-color: #006894;
}

.ui.button.green {
  background-color: #41B094;
}

.ui.button.green:hover {
  background-color: #348C76;
}


.ui.menu:not(.vertical) .item > .button.topMenu {
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 18px 22px 7px 22px;
  font-family: 'OfficinaSansITCPro-Book';
  font-size: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 0;
  color: #0082BA;
}

.ui.button.bordered:hover {
  background-color: #006894;
}
