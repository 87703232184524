/*******************************
         Site Overrides
*******************************/
.ui.small.modal {
  max-width: 500px;
}

.ui.modal > .actions {
    background: #FFF;
    border-top: 0px solid rgba(34, 36, 38, 0.15);
    display: flex;
    flex-direction: column;
    padding: 0px !important;
}

.ui.modal .actions > .button:first-child {
  background-color: #0082BA !important;
  margin-left: 0px !important;
  border: none;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 22px;
  text-align: center;
  padding: 8px;
  margin-bottom: 8px;
}

.ui.modal .actions > .button:last-child {
  border-radius: 4px;
  background-color: transparent !important;
  border: 1px solid #0082BA;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  letter-spacing: 1.5px;
  line-height: 24px;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  width: fit-content
}
.ui.modal.exercise-modal > .actions > .button:last-child {
  display: none !important;
}

.ui.modal{
  width: 100% !important;
  padding: 16px;
  box-shadow: none;
  text-align: center;
}