/*******************************
         Site Overrides
*******************************/
.ui.circular.labels .label, .ui.circular.label {
  padding: 6px 14px !important;
  width: 40px;
  letter-spacing: -1px;
}

.ui.basic.label.input-label {
  margin-bottom: 5px !important;
  line-height: inherit;
}

.ui.green.labels .label, .ui.green.label {
  color: #000000 !important;
}

.ui.label.input-label {
    border: none!important;
}